<template>
    <div  class="global-content">
        <div class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-arrow-right global-title-fa"></i><span class="global-title">请选择您要操作的设备</span><span class="global-remark">（单选）</span></div>
            <van-divider />
            <div class="handle" v-if="devData.length != 0">
                <van-radio-group class="box-container" v-model="result" direction="horizontal">
                    <van-col span="8" v-for="(item,index) in devData" :key="index">
                        <van-radio checked-color="#0957ff" :name="item.devCode">{{item.devName}}</van-radio>
                    </van-col>
                </van-radio-group>
            </div>
            <div v-else class="empty-hint">{{hintText}}</div>
        </div>
        <div class="block global-shadow">
            <div><i class="fa fa-video-camera global-title-fa"></i><span class="global-title">视频查看</span></div>
            <van-divider />
            <div class="info">
                    <video width="320" height="240" controls  ref="video"></video>
                <div><van-button color="#0957ff" class="start-video" @click="play" round>点击播放</van-button></div>
            </div>
        </div>
    </div>
</template>
<script>
let Hls = require('hls.js');
// import flvjs from "flv.js";

export default {
    data(){
        return{
            devData:[],
            result: '',
            oldDevCode:null,
            hintText:'加载中...',
            // hls: null
            flvPlayer:null,
            
        }
    },
    mounted(){
        // 获取所有设备信息
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.$api.ITGS.getAllDevInfo({
            projectCode:this.projectCode,
            controlMultiMedia:1
        }).then((data)=>{
            if(Array.isArray(data)){
                data.length == 0 ?  this.hintText='具备功能的设备列表为空' : this.devData = data;        
            }
        })
        if(this.$route.query.devCode){
            this.result = this.$route.query.devCode;
        }
        // 兼容用户直接退出微信浏览器发送请求----测试发现不可用
        window.addEventListener('pagehide',()=>{
            this.result ? this.ajaxSync(this.result) : void 0;
        });
       
    },
    methods:{
        // 原生js同步请求
        ajaxSync(devCode) {
            const token = window.localStorage.getItem("token");
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = function() {
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                    var responseValue = xmlHttp.responseText;
                }
            };
            //true：异步；false：同步
            xmlHttp.open(
                "POST",
                this.$api.ITGS.syncUrl,
                false
            );
            xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xmlHttp.setRequestHeader("Authorization", token);
            xmlHttp.send(JSON.stringify({devCode:devCode,switchStr:'off'}));
        },
        // 获取hls视频流
        getHlsStream(source) {
            if (Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(source);
                this.hls.attachMedia(this.$refs.video);

                this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log("加载成功");
                    this.$refs.video.play();
                });
                this.hls.on(Hls.Events.ERROR, (event, data) => {
                    console.log("加载失败");
                    this.$toast('加载失败，请检查设备控制是否开启')
                });
            } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                // 以下逻辑兼容IOS浏览器内核
                this.$refs.video.src = source;
                this.$refs.video.addEventListener('loadedmetadata',function() {
                    this.$refs.video.play();
                });
            }
        },
        // 停止Hls流
        videoHlsDestroyed() {
            if (this.hls) {
                this.$refs.video.pause();
                this.hls.destroy();
                this.hls = null;
            }
        },
        // 获取flv流
        // getFlvStream(source){
        //     if (flvjs.isSupported()) {
        //         this.flvPlayer = flvjs.createPlayer({
        //             type: "flv",
        //             isLive: true,
        //             hasAudio: false,
        //             url: source,
        //         });
        //         this.flvPlayer.attachMediaElement(this.$refs.video);
        //         this.flvPlayer.load(); //加载
        //         this.flvPlayer.play(); //播放
        //         // 断流重连
        //         this.flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
        //             console.log("网络波动,正在尝试连接中...")
        //         })
        //     }
        // },
        // // 销毁flv流
        // videoFlvDestroyed(){
        //     if (this.flvPlayer != null) {
        //         this.flvPlayer.pause();
        //         this.flvPlayer.unload();
        //         this.flvPlayer.detachMediaElement();
        //         this.flvPlayer.destroy();
        //         this.flvPlayer = null;
        //     }
        // },
        //打开直播控制
        onMedia(devCode){
            return new Promise((resolve,rejust)=>{
                this.$api.ITGS.subMultMedia({
                    devCode:devCode,
                    switchStr:'on',
                    projectCode:this.projectCode
                }).then(()=>{
                    resolve();
                })
            })
        },
        //关闭直播控制
        async offMedia(devCode){
            await this.$api.ITGS.subMultMedia({
                devCode:devCode,
                switchStr:'off',
                projectCode:this.projectCode
            }).then(()=>{})
        },
        // 播放流
        play(){
            if(!this.result){this.$toast('请先选择设备');return false }
            this.onMedia(this.result).then(()=>{//打开选中设备
                if(this.oldDevCode != this.result){
                    this.oldDevCode ? (this.offMedia(this.oldDevCode),this.videoHlsDestroyed()) : void 0; //关闭上一设备
                }else{
                    this.videoHlsDestroyed();
                }
                // 拼接直播流地址;注意：m3u8 用hls，flv用flv
                const source =`http://gs04.ahdcloud.com:18080/live/itgs/${this.result}/hls.m3u8`;
                // const source = `http://gs04.ahdcloud.com:18080/live/itgs/${this.result}.flv`
                const toast = this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '请稍等 20 秒',
                });
                let second = 20;
                const timer = setInterval(() => {
                    second--;
                    if (second) {
                        toast.message = `请稍等 ${second} 秒`;
                    } else {
                        clearInterval(timer);
                        // 手动清除 Toast
                        this.$toast.clear();
                    }
                }, 1000);
                setTimeout(()=>{
                    this.getHlsStream(source);   //获取流播放
                },20000)
                this.oldDevCode = this.result;   // 赋值给该设备为老设备
            });
        }
    },
    beforeDestroy(){
        this.result ? this.offMedia(this.result) : void 0;// 关闭设备控制
        this.videoHlsDestroyed(); //停止显示视频流播放
        this.$toast.clear();
    },
}
</script>
<style lang="scss" scoped>
.block{
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    .handle{
        padding: 0 4% 4% 4%;
        font-size: 0.95rem;
        .box-container{min-height:4rem;max-height:9.8rem;overflow-x: hidden;overflow-y: scroll;}
        .van-col{margin-bottom: 6%;}
    }
    .info{
        color:#656565;
        text-align: center;
        .videoPlayer{
            width: 100%;
            height: 220px;
        }
        .active{color: #0957ff;}
        .line-block{text-align: left;padding-left: 5%;}
        .van-col{margin-bottom: 8%;}
        .fa{font-size: 1.5rem;vertical-align:middle}
        .text{font-size: .85rem;padding-top: 4px;}
        .start-video{margin: 4% 0;padding: 2px 22px;height: 36px;}
    }
}
</style>